import React from 'react'
import { FileDownloadOutlined } from '@mui/icons-material'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { DatePicker, Loading, Select, GenericCardContainer } from 'components'
import { ROLES } from 'constants/Roles'
import { getSessionUser, useGetFilteredDealers } from 'hooks'
import { ReportFormProps } from './ReportFormProps'
import { CSVLink } from 'react-csv'

// return past 12 months date
const getMinDate = () => {
  const date = new Date()
  date.setMonth(date.getMonth() - 12)
  return date
}

export function ReportForm({
  title,
  reportName,
  handleSubmit,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  area,
  setArea,
  district,
  setDistrict,
  dealerId,
  setDealer,
  completedOnly,
  setCompletedOnly,
  reportData,
  isLoadingReport,
  isErrorReport,
  handleDownloadPDF,
  handleDownloadCSV,
  handleClearInputs,
  dateRequired = true
}: ReportFormProps) {
  const theme = useTheme()
  const user = getSessionUser()
  const filteredDealers = useGetFilteredDealers({ area, district })

  return (
    <GenericCardContainer>
      {filteredDealers.length <= 1 && <Loading />}
      {filteredDealers.length > 1 && (
        <Box>
          <Typography
            fontWeight={700}
            fontSize={20}
            color={theme.palette.secondary.dark}
            sx={{ mb: 1 }}>
            {title}
          </Typography>
          {dateRequired && (
            <Typography fontWeight={400} fontSize={12} color={theme.palette.secondary.dark}>
              * Required fields
            </Typography>
          )}
          <Box sx={{ mt: 4 }}>
            <form onSubmit={handleSubmit}>
              <Box display="flex">
                <Box flex={1}>
                  <DatePicker
                    inputLabel="Start Date"
                    required={dateRequired}
                    onChange={(date: string) => setFromDate(date)}
                    value={fromDate}
                    minDate={getMinDate()}
                    dateFrom={fromDate}
                    dateTo={toDate}
                    closeOnSelect={true}
                    placement="bottom-end"
                  />
                </Box>
                <Box flex={1} ml={3}>
                  <DatePicker
                    inputLabel="End Date"
                    required={dateRequired}
                    onChange={(date: string) => setToDate(date)}
                    value={toDate}
                    minDate={fromDate}
                    dateFrom={fromDate}
                    dateTo={toDate}
                    closeOnSelect={true}
                    placement="bottom-end"
                  />
                </Box>
              </Box>
              {user?.role === ROLES.ADMIN && (
                <Box mt={4} display="flex">
                  <Box flex={1}>
                    <Select
                      id="area"
                      fullWidth
                      inputlabel="Area"
                      placeholder="Select an area"
                      defaultValue={''}
                      value={area}
                      options={[
                        { label: 'Select an area', value: '' },
                        { label: 'Western', value: '31' },
                        { label: 'Central', value: '32' },
                        { label: 'Eastern', value: '33' },
                        { label: 'Southern', value: '34' }
                      ]}
                      onChange={(e) => setArea(e.target.value as string)}
                    />
                  </Box>
                  <Box flex={1} ml={3}>
                    <Select
                      id="district"
                      fullWidth
                      inputlabel="District"
                      placeholder="Select a district"
                      defaultValue={''}
                      value={district}
                      options={[
                        { label: 'Select a district', value: '' },
                        { label: '01', value: '01' },
                        { label: '02', value: '02' },
                        { label: '03', value: '03' },
                        { label: '04', value: '04' },
                        { label: '05', value: '05' },
                        { label: '06', value: '06' }
                      ]}
                      onChange={(e) => setDistrict(e.target.value as string)}
                    />
                  </Box>
                </Box>
              )}
              <Box mt={4} flex={1}>
                <Select
                  id="dealer"
                  fullWidth
                  inputlabel={`Dealer (${filteredDealers.length - 1})`}
                  placeholder="Select a dealer"
                  defaultValue={''}
                  value={dealerId}
                  options={filteredDealers}
                  onChange={(e) => setDealer(e.target.value as string)}
                />
              </Box>
              {setCompletedOnly && (
                <Box mt={2} display={'flex'} justifyContent={'end'}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked color="primary" />}
                    label="Completed only"
                    labelPlacement="start"
                    value={completedOnly}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCompletedOnly(e.target.checked as boolean)
                    }
                  />
                </Box>
              )}
              {!reportData && (
                <Box mt={4} display={'flex'} justifyContent={'end'}>
                  <Button type="submit" variant="contained" color="secondary">
                    {isLoadingReport && !isErrorReport ? (
                      <CircularProgress size={22} color="primary" />
                    ) : (
                      'RUN REPORT'
                    )}
                  </Button>
                </Box>
              )}
            </form>
          </Box>
        </Box>
      )}
      {!isLoadingReport && reportData && !isErrorReport && (
        <Box sx={{ mt: 4 }}>
          <Typography
            fontWeight={700}
            fontSize={20}
            color={theme.palette.secondary.dark}
            sx={{ mb: 1 }}>
            Report
          </Typography>
          <Typography fontWeight={400} fontSize={16} color={theme.palette.primary.dark}>
            Click to view the report for, the report is PDF or CSV format.
          </Typography>
          <Box mt={4} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'}>
              <FileDownloadOutlined sx={{ mr: 1 }} />
              <Typography fontWeight={400} fontSize={18} color={theme.palette.secondary.dark}>
                {reportName}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Button type="button" variant="text" color="secondary" onClick={handleDownloadPDF}>
                DOWNLOAD PDF
              </Button>
              <CSVLink
                style={{ textDecoration: 'none' }}
                filename={`${new Date().toISOString()}-acceptance-rate-report.csv`}
                data={handleDownloadCSV()}>
                <Button type="button" variant="text" color="secondary">
                  DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Box>
          </Box>
          <Box mt={4} display={'flex'} justifyContent={'end'}>
            <Button type="button" variant="contained" color="secondary" onClick={handleClearInputs}>
              CLEAR INPUTS
            </Button>
          </Box>
        </Box>
      )}
    </GenericCardContainer>
  )
}
