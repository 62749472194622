import React, { useState } from 'react'
import { jsPDF, jsPDFOptions } from 'jspdf'
import autoTable, { UserOptions } from 'jspdf-autotable'
import { Container } from 'components'
import { ROLES } from 'constants/Roles'
import { REGION_MAP } from 'constants/Region'
import { ReportForm } from './ReportForm'
import { NotFoundDialog } from './NotFoundDialog'
import {
  ReportAcceptanceRateResponse,
  ReportRequest,
  useReportAcceptanceRate,
  getSessionUser
} from 'hooks'
import { ErrorDialog } from './ErrorDialog'
import { ReportFormDealer } from './ReportFormDealer'

function getDate(month: string) {
  const [year, monthNumber] = month.split('-')
  const monthName = new Date(Number(year), Number(monthNumber) - 1).toLocaleString('default', {
    month: 'long'
  })

  return `${monthName} ${year}`
}

export function ReportsAcceptanceRate() {
  const user = getSessionUser()
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [area, setArea] = useState('')
  const [district, setDistrict] = useState('')
  const [dealerId, setDealer] = useState('')
  const [reportData, setReportData] = useState<ReportAcceptanceRateResponse>()
  const [showNotFoundDialog, setShowNotFoundDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    mutateAsync: getAcceptanceRate,
    isLoading: isLoadingReport,
    isError: isErrorReport
  } = useReportAcceptanceRate()

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    const request: ReportRequest = {
      fromDate: fromDate.toString(),
      toDate: toDate.toString()
    }

    if (user?.role === ROLES.ADMIN) {
      request.area = area
      request.district = district
      request.dealerId = dealerId
    } else if (user?.role === ROLES.MANAGER) {
      request.area = String(user?.region)
      request.dealerId = dealerId
    } else if (user?.role === ROLES.DEALER) {
      request.dealerId = String(user?.dealerCode)
    }

    try {
      const response = await getAcceptanceRate(request)
      if (!isErrorReport && Object.keys(response).length > 0) {
        setReportData(response)
      } else {
        setShowNotFoundDialog(true)
      }
    } catch (error) {
      setErrorMessage(error.response.data.message)
    }
  }

  const handleClearInputs = () => {
    setFromDate(null)
    setToDate(null)
    setArea('')
    setDistrict('')
    setDealer('')
    setReportData(undefined)
  }

  const handleDownloadData = () => {
    const months = reportData && Object.keys(reportData)
    const monthsData = months && months.map((key) => reportData[key])

    return { months, monthsData }
  }

  const handleDownloadPDF = () => {
    const docConfig: jsPDFOptions = {
      format: 'a4',
      orientation: 'landscape'
    }
    const doc = new jsPDF(docConfig)
    const head = [
      {
        id: undefined,
        accepted: 'Accepted',
        declined: 'Declined',
        totalNotAnswered: 'Unanswered',
        total: 'Total',
        acceptanceRate: 'MTD % Accepted',
        last12MonthsAcceptanceRate: 'Rolling 12 Month % Accepted',
        totalDeclinedClosed: 'Closed',
        totalDeclinedVehicleNotAvailable: 'Vehicle NA',
        totalDeclinedOtherCall: 'Other Call',
        totalDeclinedUnavailable: 'Tech NA',
        totalDeclinedDistance: 'Distance',
        totalDeclinedWeather: 'Weather'
      }
    ]
    const options: Partial<UserOptions> = {
      head,
      startY: 50,
      headStyles: {
        halign: 'center',
        valign: 'middle',
        minCellWidth: 12,
        fillColor: '#648EAE',
        fontSize: 9,
        fontStyle: 'bold',
        lineWidth: 0.1
      },
      bodyStyles: {
        halign: 'right',
        valign: 'middle',
        fontSize: 10
      },
      alternateRowStyles: {
        fillColor: '#EDF2F6'
      },
      columnStyles: {
        id: {
          minCellWidth: 75,
          halign: 'left'
        },
        acceptanceRate: {
          minCellWidth: 20
        },
        last12MonthsAcceptanceRate: {
          minCellWidth: 20
        }
      },
      willDrawCell: (data) => {
        if (
          ['WESTERN AREA', 'CENTRAL AREA', 'EASTERN AREA', 'SOUTHERN AREA'].includes(
            data.cell.text[0]
          )
        ) {
          doc.setFont(doc.getFont().fontName, 'bold')
          Object.entries(data.row.cells).forEach((cell) => {
            cell[1].styles.fontStyle = 'bold'
            cell[1].styles.fillColor = '#b9ffca'
          })
          return
        }

        if (data.column.dataKey === 'id' && !isNaN(parseInt(data.cell.text[0].split(' ')[0]))) {
          data.cell.text[0] = `    ${data.cell.text[0]}`
        }
      }
    }

    const { months, monthsData } = handleDownloadData()

    monthsData.forEach((month, index) => {
      month.forEach((area) => {
        doc.setFontSize(20)
        doc.setFont(doc.getFont().fontName, 'normal', 700)
        doc.text('Lexus Dealer Roadside Acceptance Rates', 10, 20)
        doc.setFontSize(16)
        doc.text(getDate(months[index]), 10, 26)
        doc.text(REGION_MAP[area.areaId], 10, 35)
        autoTable(doc, {
          ...options,
          body:
            area.items &&
            area.items?.map((item) => {
              return {
                id: item.id,
                accepted: item.accepted,
                declined: item.declined,
                totalNotAnswered: item.totalNotAnswered,
                total: item.totalCalls,
                acceptanceRate: `${item.mtdAcceptanceRate.toFixed(2)}%`,
                last12MonthsAcceptanceRate: `${item.last12MonthsAcceptanceRate.toFixed(2)}%`,
                totalDeclinedClosed: item.totalDeclinedClosed,
                totalDeclinedVehicleNotAvailable: item.totalDeclinedVehicleNotAvailable,
                totalDeclinedOtherCall: item.totalDeclinedOtherCall,
                totalDeclinedUnavailable: item.totalDeclinedUnavailable,
                totalDeclinedDistance: item.totalDeclinedDistance,
                totalDeclinedWeather: item.totalDeclinedWeather
              }
            })
        })

        doc.addPage(docConfig.format, docConfig.orientation)
        doc.setPage(doc.getNumberOfPages())
      })
    })

    doc.deletePage(doc.getNumberOfPages())
    doc.save(`${new Date().toISOString()}-acceptance-rate-report.pdf`)
  }

  const getEmptyRow = (head: string[]) => Object.values(head).map(() => '')

  const handleDownloadCSV = () => {
    const head = [
      '',
      'Accepted',
      'Declined',
      'Unanswered',
      'Total',
      'MTD % Accepted',
      'Rolling 12 Month % Accepted',
      'Closed',
      'Vehicle NA',
      'Other Call',
      'Tech NA',
      'Distance',
      'Weather'
    ]

    const { months, monthsData } = handleDownloadData()

    const csvData = [getEmptyRow(head)]

    monthsData.forEach((month, index) => {
      month.forEach((area) => {
        const title = getEmptyRow(head)
        title[0] = 'Lexus Dealer Roadside Acceptance Rates'
        csvData.push(title)

        const date = getEmptyRow(head)
        date[0] = getDate(months[index])
        csvData.push(date)

        const region = getEmptyRow(head)
        region[0] = REGION_MAP[area.areaId]
        csvData.push(region)

        csvData.push(getEmptyRow(head))

        csvData.push(head)

        area.items.map((item) => {
          csvData.push([
            item.id,
            item.accepted.toString(),
            item.declined.toString(),
            item.totalNotAnswered.toString(),
            item.totalCalls.toString(),
            `${item.mtdAcceptanceRate.toFixed(2)}%`,
            `${item.last12MonthsAcceptanceRate.toFixed(2)}%`,
            item.totalDeclinedClosed.toString(),
            item.totalDeclinedVehicleNotAvailable.toString(),
            item.totalDeclinedOtherCall.toString(),
            item.totalDeclinedUnavailable.toString(),
            item.totalDeclinedDistance.toString(),
            item.totalDeclinedWeather.toString()
          ])
        })

        csvData.push(getEmptyRow(head))
      })

      csvData.push(getEmptyRow(head))
      csvData.push(getEmptyRow(head))
    })

    return csvData
  }

  return (
    <Container
      title="Acceptance Rate Report"
      subtitle="Use this screen to choose the area and year/month for which you want to view the report. This is a monthly summary. Up to 12 months' reports will be retained on this website for viewing.">
      {user?.role === ROLES.DEALER ? (
        <ReportFormDealer
          title="Search for Acceptance Rate Report"
          reportName="Acceptance Report"
          handleSubmit={handleSubmit}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          reportData={reportData}
          isLoadingReport={isLoadingReport}
          isErrorReport={isErrorReport}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadCSV={handleDownloadCSV}
          handleClearInputs={handleClearInputs}
        />
      ) : (
        <ReportForm
          title="Search for Acceptance Rate Report"
          reportName="Acceptance Report"
          handleSubmit={handleSubmit}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          area={area}
          setArea={setArea}
          district={district}
          setDistrict={setDistrict}
          dealerId={dealerId}
          setDealer={setDealer}
          reportData={reportData}
          isLoadingReport={isLoadingReport}
          isErrorReport={isErrorReport}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadCSV={handleDownloadCSV}
          handleClearInputs={handleClearInputs}
        />
      )}
      <NotFoundDialog open={showNotFoundDialog} onClose={() => setShowNotFoundDialog(false)} />
      <ErrorDialog
        content={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
    </Container>
  )
}
