export const MANAGERS_INFO = {
  EASTERN: [
    {
      name: "Kyle Pippitt",
      email: 'Kyle.Pippitt@lexus.com',
      area: 'Eastern Area'
    }
  ],
  WESTERN: [
    {
      name: 'Myra Tretter',
      email: 'Myra.Tretter@toyota.com',
      area: 'Western Area'
    },
    {
      name: 'David Dawood',
      email: 'david.dawood@toyota.com',
      area: 'Western Area'
    }
  ],
  CENTRAL: [
    {
      name: 'Taurest Dillard',
      email: 'taurest.dillard@toyota.com',
      area: 'Central Area'
    },
    {
      name: 'Bonier Avila',
      email: 'bonier.avila@toyota.com',
      area: 'Central Area'
    },
  ],
  SOUTHERN: [
    {
      name: 'Deborah Velazquez',
      email: 'deborah.velazquez@lexus.com',
      area: 'Southern Area'
    },
    {
      name: 'Christopher Reid',
      email: 'christopher.reid@toyota.com',
      area: 'Southern Area'
    },
  ]
}
